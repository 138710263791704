import website from '../assets/website.png'
import instagram from '../assets/instagram.png'
import appstore from '../assets/playstore.png'
import youtube from '../assets/youtube.jpeg'
import dribbble from '../assets/dribbble.png'
import github from '../assets/github.png'
import telegram from '../assets/telegram.png'
import twitter from '../assets/twitter.png'

const items = [
    {
        "title": "civic.me/juamps.sol",
        "subtitle": "My Web3 Profile",
        "image": website,
        "link": "https://civic.me/juamps.sol" //civic.me  link
    },
    {
        "title": "@juamps",
        "subtitle": "twitter",
        "image": twitter,
        "link": "https://twitter.com/juamps"// twitter profile link 
    },
    {
        "title": "jpbedoya",
        "subtitle": "Telegram",
        "image": telegram,
        "link": "https://telegram.me/jpbedoya" //Telegram Pofile 
    },
    {
        "title": "LinkedIn",
        "subtitle": "My LinkedIn profile",
        "image": website,
        "link": "https://www.linkedin.com/in/bedoya/"// LI profile link 
    },
    {
        "title": "civic.com",
        "subtitle": "Identity for Web3 Apps",
        "image": website,
        "link": "https://www.civic.com" //civic.com  link
    },
    {
        "title": "Integration Docs",
        "subtitle": "Resources to use civic in your dApp",
        "image": website,
        "link": "https://docs.civic.com" //civic.com  link
    }    
]

export default items
